import { AbstractControl } from '@angular/forms';

//validate the company URL.
export function ValidateUrl(control: AbstractControl) {
  if (!control.value.startsWith('www')) {
    return { validUrl: true };
  }
  return null;
}

//for numeric validation
export function NumberValidation(control: AbstractControl) {

  const pattern = /^[0-9]*$/.test(control.value);
  return pattern ?null:{invalidNumber:{valid:false,value:control.value}};
  // if (!pattern.test(control.value)) {
  //   return { validUrl: true };
  // }
  // return null;
}

export function phoneNumberValidator(control: AbstractControl): {
  [key: string]: any } | null {
  const valid = /^[0-9()-]+$/.test(control.value);
  return valid ? null : { invalidNumber: { valid: false, value: control.value } };
}