export const environmentOkta = {
    
    /* DEV Personal account */
    /* baseUrl: 'https://dev-71656292.okta.com/oauth2/default',
    clientId: '0oa1xdi6ldQDpV8iw5d7',
    redirectUri: 'https://prospectivesupplier-q.lilly.com/login/callback',
    issuer: 'https://dev-71656292.okta.com/oauth2/default'*/


    /* QA */
    baseUrl: 'https://lilly.okta.com/oauth2/default',
    clientId: '0oaaxanixlCHT6jNz297',
    redirectUri: 'https://prospectivesupplier.lilly.com/login/callback',
    issuer: 'https://lilly.okta.com/oauth2/default'
}


