/*********************************************************************************
Class Name      : CacheInterceptor
Description     : This class is used to make IE compatibility
Created By      : TCS               
Created Date    : 28-Jan-2019  
---------------------------------------------------------------------------------- 
Developer:OSF_Dev_Team                   Date : 28-Jan-2019                  
-----------------------------------------------------------------------------------           
*********************************************************************************/ 

import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpHeaders } from '@angular/common/http';

@Injectable()
export class CacheInterceptor implements HttpInterceptor {

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    if(sessionStorage.getItem('jwt')!=undefined){
      const httpRequest = req.clone({
        headers: new HttpHeaders({
          'Cache-Control': 'no-cache',
          'Pragma': 'no-cache',
          'x-auth-token': sessionStorage.getItem('jwt'),
          'loggedinuser': sessionStorage.getItem('loginEmailId'),
          'lilly':'lilly'
        })
      });
  
      return next.handle(httpRequest);
    }
    else{
      const httpRequest = req.clone({
        headers: new HttpHeaders({
          'Cache-Control': 'no-cache',
          'Pragma': 'no-cache',
          'lilly':'lilly'
        })
      });
  
      return next.handle(httpRequest);
    }
  }
}
