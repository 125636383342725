/*********************************************************************************
Component Name      : DefaultAddMeComponent
Description     : This component is used to display duplicate suppliers
Created By      : TCS               
Created Date    : 28-Jan-2019  
---------------------------------------------------------------------------------- 
Developer:OSF_Dev_Team                   Date : 28-Jan-2019                  
-----------------------------------------------------------------------------------           
*********************************************************************************/  

import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { DropdownService } from '../../dropdown.service';
import { Env } from 'src/env-variables/env';
import { Router, ActivatedRoute } from '@angular/router';

declare let swal: any;

@Component({
  selector: 'app-default-add-me',
  templateUrl: './default-add-me.component.html',
  styleUrls: ['./default-add-me.component.css']
})

export class DefaultAddMeComponent implements OnInit {
  countryList: Array<any>;
  addContactInfo: FormGroup;
  submitted = false;
  emailId: string = '';
  firstName: string = '';
  lastName: string = '';
  country: string = '';
  companyName: string = '';
  checkCompany: string = "";
  checkEmailId: string = "";
  userCountry: string = "";
  userEmail: string = "";
  userName: string = "";
  userFirstName: string = "";
  userLastName: string = "";
  tempDup: Array<any>;

  duplicateCompList: Array<any>;

  typesOfSecurity: { name: string }[] = [
    { name: 'Primary:"EDIT"' },
    { name: 'Read Only' },
  ];
  model: any = {};
  model2: any = {};
  msg: any = "";
  updateFlag: boolean = false;


  myValue;
  URL: string;
  constructor(private dropdownSvc: DropdownService, private env: Env,private router: Router) {
    this.URL = env.Prospective_URL;
  }

  ngOnInit() {

    if (localStorage.getItem('loginEmailId') != null) {
      this.emailId = localStorage.getItem('loginEmailId')
    }

    if (localStorage.getItem('firstName') != null) {
      this.firstName = localStorage.getItem('firstName')
    }

    if (localStorage.getItem('country') != null) {
      this.country = localStorage.getItem('country').toUpperCase()
    }

    if (localStorage.getItem('lastName') != null) {
      this.lastName = localStorage.getItem('lastName')
    }

    if (localStorage.getItem('companyName') != null) {
      this.companyName = localStorage.getItem('companyName')
    }

    var obj = {
      checkCompany: this.companyName,
      checkEmailId: this.emailId
    }

    this.dropdownSvc.getDuplicateCompanyList(obj).subscribe((data) => {
      this.duplicateCompList = data.data;
      this.duplicateCompList = this.duplicateCompList.filter((obj, index, self) =>
        index === self.findIndex((t) => (
          t.CompanyName === obj.CompanyName && t.country === obj.country
        ))
      )
    });

  }

  clickMe() {
    this.msg = "";
  }

  addMe(k) {

    swal({
      title: "Do you really want to send a Contact Addition Request?",
      text: "Once you press 'OK', then the email will be send to all the supplier's primary contact(s)",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        var mailObj = {
          checkCompany: k,
          userCountry: this.country,
          userEmail: this.emailId,
          userFirstName: this.firstName,
          userLastName: this.lastName
        }
        this.dropdownSvc.postAddMeEmail(mailObj).subscribe((data) => {
          if (data.status == 'success') {
            swal({
              title: "Contact addition request.",
              text: "To add your contact information, an email has been sent to all the primary contact(s) of your company",
              icon: "success"
            })
          }
          else {
            swal({
              title: "Request Not Send.",
              text: "There was an error please try to submit again.",
              icon: "warning"
            })
          }
        });
      }
    });


  }

  createData() {
    localStorage.setItem('showAddMeFlag', 'true');
    localStorage.setItem('showCreateFlag', 'true');
    this.router.navigate(['/create']);
  }
}
