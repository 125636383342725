import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import {RouterModule,Routes} from '@angular/router';
import {HttpClientModule} from '@angular/common/http';
import { ReactiveFormsModule , FormsModule } from '@angular/forms';
import {MatToolbarModule,MatFormFieldModule,MatTableModule,MatInputModule,MatOptionModule,MatSelectModule,MatIconModule,MatButtonModule,MatCardModule,MatTabsModule,MatDividerModule,MatSnackBarModule, MatDrawer, MatDrawerContainer} from '@angular/material';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CreateComponent } from './components/create/create.component';
import { NgbdCarouselConfig } from './components/edit/edit.component';
import {MatSidenavModule} from '@angular/material/sidenav';
import { GeneralInfoComponent } from './components/general-info/general-info.component';
import { HomeComponent } from './home/home.component';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatStepperModule , MatMenuModule , MatNativeDateModule, MatProgressSpinnerModule} from '@angular/material';
import {MatCheckboxModule} from '@angular/material/checkbox'
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatRadioModule} from '@angular/material/radio';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import { GoogleChartsModule } from 'angular-google-charts';
import Chart from 'chart.js';
import { SliderModule } from 'angular-image-slider';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {NgbPaginationModule, NgbAlertModule} from '@ng-bootstrap/ng-bootstrap';
import {MatExpansionModule} from '@angular/material/expansion';
import { PrintComponent } from './components/print/print.component';
import * as jspdf from 'jspdf';  
import html2canvas from 'html2canvas';  
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatGridListModule} from '@angular/material/grid-list';
import {MatListModule} from '@angular/material/list';
import { AgGridModule } from 'ag-grid-angular';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatSortModule} from '@angular/material/sort';
import { UpdateComponent } from './components/update/update.component';
import { LoginComponent } from './login/login.component';
import { DialogElementsExampleDialog } from './login/login.component';
import { AuthGuard } from './auth/auth.guard';
import { DefaultAddMeComponent } from './app/default-add-me/default-add-me.component';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {StateFilter} from './components/filter/state-filter.pipe';
import { UserIdleModule } from 'angular-user-idle';
import {HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgProgressModule, NgProgressInterceptor } from './../../node_modules/ngx-progressbar';
import { CacheInterceptor } from './customhttpinterceptor';

import {
  OKTA_CONFIG,
  OktaAuthGuard,
  OktaAuthModule,
  OktaCallbackComponent,
} from '@okta/okta-angular';

import sampleConfig from './app.config';
import {  Router } from '@angular/router';

const oktaConfig = Object.assign({
  onAuthRequired: (oktaAuth, injector) => {
    const router = injector.get(Router);
    // Redirect the user to your custom login page
    //router.navigate(['/login']);
  }
}, sampleConfig.oidc);


const routes: Routes = [
  {
    path: 'create', component: CreateComponent,
    //children: [{ path: '', component: CreateComponent }],
    //canActivate:[AuthGuard]
  },

  { path: 'home', component: HomeComponent, canActivate:[AuthGuard] },
  
  { path: 'login/callback', component: OktaCallbackComponent },

  { path: 'login', component: LoginComponent, },

  {
    path: 'edit', component: NgbdCarouselConfig,
    //children: [{ path: '', component: NgbdCarouselConfig }],
    canActivate:[AuthGuard]
  },
  {
    path: 'general-info/:email', component: GeneralInfoComponent,
    //children: [{ path: '', component: GeneralInfoComponent }],
    canActivate:[AuthGuard] 
  },
   {path: '', redirectTo:'login', pathMatch:'full'},
  {
    path: 'updateSupplierForm/:email', component: UpdateComponent,
    //children: [{ path: '', component: UpdateComponent }],
    canActivate:[AuthGuard] 
  },
  {
    path:'addme', component: DefaultAddMeComponent,
    //children:[{path:'',component:UpdateComponent}],
    canActivate:[AuthGuard]
  }
 
];


@NgModule({
  entryComponents: [DialogElementsExampleDialog],
  declarations: [
    AppComponent,
    CreateComponent,
    NgbdCarouselConfig,
    GeneralInfoComponent,
    HomeComponent,
    PrintComponent,
    UpdateComponent,
    LoginComponent,
    DefaultAddMeComponent,
    StateFilter,
    DialogElementsExampleDialog
  
    
  ],
  imports: [
    BrowserModule,
    FormsModule,
    BrowserAnimationsModule,
    HttpClientModule,
    RouterModule.forRoot(routes),
    MatToolbarModule,
    MatFormFieldModule,
    MatProgressBarModule,
MatInputModule,
MatOptionModule,
MatSelectModule,
MatIconModule,
MatButtonModule,
MatCardModule,
MatTabsModule,
MatDividerModule,
MatSnackBarModule,
ReactiveFormsModule,
MatTableModule,
MatSidenavModule,
MatAutocompleteModule,
MatStepperModule,
MatMenuModule,
MatListModule,
MatCheckboxModule,
MatDatepickerModule,
MatNativeDateModule,
MatRadioModule,
MatSlideToggleModule,
GoogleChartsModule ,
SliderModule,
NgbModule,
NgbPaginationModule,
NgbAlertModule,
MatExpansionModule,
MatTooltipModule,
MatGridListModule,
MatProgressSpinnerModule,
AgGridModule.withComponents([]),
MatPaginatorModule,
MatSortModule,
NgProgressModule,
UserIdleModule.forRoot({idle: 1800, timeout: 10, ping: 120}),
OktaAuthModule,HttpClientModule
//MDBBootstrapModule.forRoot()
  ],
  providers: [AuthGuard,{ provide: HTTP_INTERCEPTORS, useClass: NgProgressInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: CacheInterceptor, multi: true },{ provide: OKTA_CONFIG, useValue: oktaConfig }],
  bootstrap: [AppComponent]
})
export class AppModule { }
