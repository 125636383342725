/*********************************************************************************
Component Name      : GeneralInfoComponent
Description     : This component is used to view supplier's detail
Created By      : TCS               
Created Date    : 28-Jan-2019  
---------------------------------------------------------------------------------- 
Developer:OSF_Dev_Team                   Date : 28-Jan-2019                  
-----------------------------------------------------------------------------------           
*********************************************************************************/  

import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import * as jspdf from 'jspdf';
import html2canvas from 'html2canvas';
import { OnSubmitService } from './../../on-submit.service';
import { ViewData } from 'src/app/assets/VIewData';

declare let swal: any;
export interface PYRevenue {
  value: string;
  viewValue: string;
}
export interface CYRevenue {
  value: string;
  viewValue: string;
}
export interface SACovered {
  value: string;
  viewValue: string;
 }

@Component({
  selector: 'app-general-info',
  templateUrl: './general-info.component.html',
  styleUrls: ['./general-info.component.css']
})
export class GeneralInfoComponent implements OnInit {
  updateForm: FormGroup;
  panelOpenState = false;
  referencesList=[];
  contactsList=[];
  flag:boolean;
  email:string;
  regId:any;
  subsidiaryFlag:boolean;
  parentFlag:boolean;
  diversityFlag:boolean;
  
  constructor(private fb: FormBuilder,private onSubmitSvc:OnSubmitService,private route: ActivatedRoute,private router:Router) { }

  ngOnInit() {

    this.route.params.subscribe(params => {
      this.email=params['email'];

    });
    
    this.updateForm = this.fb.group({
      Company: new FormControl({value:'', disabled: true}),
      Business: new FormControl({value:'', disabled: true}),
      Year: new FormControl({value:'', disabled: true}),
      country: new FormControl({value:'', disabled: true}),
      cyr: new FormControl({value:'', disabled: true}),
      pyr: new FormControl({value:'', disabled: true}),
      TotalEmp: new FormControl({value:'', disabled: true}),
      TN: new FormControl({value:'', disabled: true}),
      URL: new FormControl({value:'', disabled: true}),
      OrgType: new FormControl({value:'', disabled: true}),
      BusinessType: new FormControl({value:'', disabled: true}),
      Subsidary: new FormControl({value:'', disabled: true}),
      ParentName: new FormControl({value:'', disabled: true}),
      DiversityStatus: new FormControl({value:'',disabled:true}),
      refname: new FormControl({value:'', disabled: true}),
      reftitle: new FormControl({value:'', disabled: true}),
      refphone: new FormControl({value:'', disabled: true}),
      refemail: new FormControl({value:'', disabled: true}),
      contactsemail: new FormControl({value:'', disabled: true}),
      contactsphone: new FormControl({value:'', disabled: true}),
      contactstitle: new FormControl({value:'', disabled: true}),
      contactsname: new FormControl({value:'', disabled: true}),
      majorbusinesscategory: new FormControl({value:'', disabled: true}),
      valueproposition: new FormControl({value:'', disabled: true}),
      goodsandservices: new FormControl({value:'', disabled: true}),
      subcategory: new FormControl({value:'', disabled: true}),
      SAArea: new FormControl({value:'', disabled: true}),
    });
    
 this.onSubmitSvc.getView(this.email).subscribe((data:  ViewData) => {
   
   if(data.generalinformationdata[0].subsidary=="Yes"){
     this.parentFlag=false;
     this.subsidiaryFlag=true;
   }
   else{
     this.parentFlag=true;
     this.subsidiaryFlag=false;
   }
   
   if(data.generalinformationdata[0].country.toLocaleLowerCase()=="united states"){
     this.diversityFlag=false;
   }
   else{
     this.diversityFlag=true;
   }
  this.regId=data.generalinformationdata[0].registrationid;
  var a=data.generalinformationdata[0].salesareacovered;
      a=JSON.parse(a);
      var sacov="";
      for(var i=0;i<a.length;i++){
        sacov+=a[i];
        if(i==a.length-1){
        sacov+=".";}
        else{
          sacov+=", ";
        }
      }
      var b=data.goodandservicesdata[0].majorbusinesscategory;
      b=JSON.parse(b);
      var mjbus="";
      for(var i=0;i<b.length;i++){
        mjbus+=b[i];
        if(i==b.length-1){
          mjbus+=".";}
          else{
            mjbus+=", ";
          }
      }
      var c=data.goodandservicesdata[0].subbusinesscategory;
      if(c!=null){
      c=JSON.parse(c);
      var subbus="";
      for(var i=0;i<c.length;i++){
        subbus+=c[i];
        if(i==c.length-1){
          subbus+=".";}
          else{
            subbus+=", ";
          }
      }}
      var d=data.generalinformationdata[0].diversitystatus;
      d=JSON.parse(d);
      var divstat="";
      for(var i=0;i<d.length;i++){
        divstat+=d[i];
        if(i==d.length-1){
          divstat+=".";}
          else{
            divstat+=", ";
          }
      }
      this.referencesList=data.referencesdata;
      this.contactsList=data.contactsdata;
      var refFullName="";
      var refTitle="";
      var refPhone="";
      var refEmail="";
      
      if(this.referencesList.length==0){
        this.flag=true;
      }else{
        this.flag=false;
        refFullName=data.referencesdata[0].firstname+" "+data.referencesdata[0].lastname;
        refPhone=data.referencesdata[0].faxnumber;
        refEmail=data.referencesdata[0].email;
        refTitle=data.referencesdata[0].title;
      }

      this.updateForm.setValue({
        Company: data.generalinformationdata[0].companyname,
        Business: data.generalinformationdata[0].doingbusas,
        Year: data.generalinformationdata[0].year,
        cyr: data.generalinformationdata[0].cyr,
        pyr: data.generalinformationdata[0].pyr,
        TotalEmp: data.generalinformationdata[0].totalemployees,
        country: data.generalinformationdata[0].country,
        TN: data.generalinformationdata[0].telephoneno,
        URL: data.generalinformationdata[0].companyurl,
        SAArea:sacov,
        OrgType: data.generalinformationdata[0].organizationtype,
        BusinessType: data.generalinformationdata[0].businesstype,
        Subsidary: data.generalinformationdata[0].subsidary,
        ParentName: data.generalinformationdata[0].parentlegalname,
        DiversityStatus:divstat,
        refname: refFullName,
        reftitle: refTitle,
        refphone: refPhone,
        refemail: refEmail,
        contactsemail: data.contactsdata[0].email,
        contactsphone: data.contactsdata[0].faxnumber,
        contactstitle: data.contactsdata[0].title,
        contactsname: data.contactsdata[0].firstname+" "+data.contactsdata[0].lastname,
        majorbusinesscategory: mjbus,
        valueproposition: data.goodandservicesdata[0].valuesproposition,
        goodsandservices: data.goodandservicesdata[0].description,
        subcategory: subbus});
    });

    // When the user scrolls down 20px from the top of the document, show the button
    window.onscroll = function () { scrollFunction() };
    function scrollFunction() {
      if (document.body.scrollTop > 20 || document.documentElement.scrollTop > 20) {
        document.getElementById("myBtn").style.display = "block";
      } else {
        document.getElementById("myBtn").style.display = "none";
      }
    }
  }

  // When the user clicks on the button, scroll to the top of the document
  topFunction() {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }

  print(): void {
    window.open("/print");
  }

  callUpdate():void{
    this.router.navigate(['/updateSupplierForm/'+this.email], { skipLocationChange: true });
  }

  captureScreen() {
    var data = document.getElementById('contentToConvert');
    html2canvas(data).then(canvas => {
      // Few necessary setting options  
      var imgWidth = 170;
      var pageHeight = 1000;
      var imgHeight = canvas.height * imgWidth / canvas.width;
      var heightLeft = imgHeight;

      const contentDataURL = canvas.toDataURL('image/png')
      let pdf = new jspdf('p', 'mm', 'a4'); // A4 size page of PDF  
      var position = 0;
      var width = pdf.internal.pageSize.width;
      var height = pdf.internal.pageSize.height;
      pdf.addImage(contentDataURL, 'PNG', 0, position, imgWidth, imgHeight)
      pdf.save('View.pdf'); // Generated PDF   
    });
  }
}
