import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot,Router } from '@angular/router';
import { Env } from 'src/env-variables/env';
import { OktaAuth } from '@okta/okta-auth-js'
import { environmentOkta } from 'src/env-variables/environmentOkta';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate{
  URL:string;
  constructor(private env:Env,private route:Router) {
    this.URL=env.Prospective_URL;
  }
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot):boolean {
      var flag=false;
      /*if(localStorage.janrainCaptureProfileData_Expires!=undefined){
        if((((new Date((new Date()).toUTCString())).getTime())-((new Date(localStorage.getItem('janrainCaptureProfileData_Expires'))).getTime()))>0){
        localStorage.clear();
      } 
      }*/
      var authClient=new OktaAuth({issuer: environmentOkta.issuer, clientId: environmentOkta.clientId})
      authClient.tokenManager.get('idToken').then(function(token) {
        if (token && !authClient.tokenManager.hasExpired(token)) {
          console.log('line 26',token);
        } else {
          localStorage.clear();
      }}).catch(function(err) {
        console.error(err);
  });  

      
      if(localStorage.janrainCaptureProfileData_Expires==undefined){
      console.log("test");
      }
      
      if (localStorage.getItem('userToken')=='true')
      {
            flag=true;
      }
      else
      {
         window.location.replace('/login')
      }

      if(state.url==='/home'){
        if(localStorage.getItem('showcreate')=="false"){
          this.route.navigate(['/addme']);
        }
        else{
          flag=true;
        }
      }

      if(state.url==='/create'){
        if(localStorage.getItem('showCreateFlag')!='true'){
          //flag=false;
          window.location.replace('/create') 
        }
      }
      if(state.url.indexOf('updateSupplierForm')!=-1){
        if(localStorage.getItem('afteraddme')=='true'){
          return true;
        }
        if(localStorage.getItem('showCreateFlag')=='true'){
          flag=false;
          window.location.replace('/home') 
        }
      }
      if(state.url.indexOf('general-info')!=-1){
        if(localStorage.getItem('afteraddme')=='true'){
          return true;
        }
        if(localStorage.getItem('showCreateFlag')=='true'){
          flag=false;
          window.location.replace('/home'); 
        }
      }
      return flag;
   }
}
