import { environmentOkta } from 'src/env-variables/environmentOkta';

export default {
  oidc: {
    clientId: environmentOkta.clientId,
    issuer: environmentOkta.baseUrl,
    redirectUri: environmentOkta.redirectUri,
    scopes: ['openid', 'profile', 'email'],
    testing: {
      disableHttpsCheck: `true`
    },
    useInteractionCodeFlow: `false`,
  },
  resourceServer: {
    messagesUrl: 'http://localhost:8000/api/messages',
  },
};
