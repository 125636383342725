/*********************************************************************************
Component Name      : CreateComponent
Description     : This component is used to create supplier's registration form 
Created By      : TCS               
Created Date    : 28-Jan-2019  
---------------------------------------------------------------------------------- 
Developer:OSF_Dev_Team                   Date : 28-Jan-2019                  
-----------------------------------------------------------------------------------           
*********************************************************************************/  

import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators, FormArray } from '@angular/forms';
import { ValidateUrl, NumberValidation, phoneNumberValidator } from './validators';
import { ValueTransformer } from '@angular/compiler/src/util';
import { MatTableDataSource } from '@angular/material';
import { Observable } from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';
import { map, startWith } from 'rxjs/operators';
import { timer } from 'rxjs/observable/timer';
import { DropdownService } from './../../dropdown.service';
import { OnSubmitService } from './../../on-submit.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';

declare let swal: any;

@Component({
  selector: 'app-create',
  templateUrl: './create.component.html',
  styleUrls: ['./create.component.css']
})
export class CreateComponent implements OnInit {
  firstFormGroup: FormGroup;
  supplierForm: FormGroup;
  goodsAndServices: FormGroup;
  registerForm: FormGroup;
  supplierReferencesForm: FormGroup;
  selectedValue: boolean = false;
  submitted = false;

  myControl = new FormControl();
  filteredOptions: Observable<string[]>;

  diversityStatusFlag = false;
  disabledCountryFlag = false;
  disabledCompanyFlag = false;
  parentLegalNameFlag = false;
  countryList: Array<any>;
  countryStateList: Array<any>=[];
  cities: Array<any>;
  majorBusinessCategoryList: Array<any>;
  stateList: Array<any>=[];
  businessList: Array<any>;
  subCategories: Array<any>;
  totalEmps: Array<any>;
  PyrList: Array<any>;
  CyrList: Array<any>;
  SalesAreaList: Array<any>;
  OrgType: Array<any>;
  BusType: Array<any>;
  errors: Array<any> = [];
  emailId: string = '';
  firstName: string = '';
  LastName: string = '';
  country: string = '';
  companyName: string = '';
  emailList: Array<any>;
  url: string;


  changeCountry(count) {
    
    for (var j = 0; j < this.countryStateList.length; j++) {
      if (count == this.countryStateList[j].countrycode) {
        this.stateList.push(this.countryStateList[j].state);
      }
    }
    
  }

  options: string[] = ['One', 'Two', 'Three'];

  
  countryGenrealList: Array<any>;

  countryDiverityFlag(value) {
    if (value.toLowerCase() === 'united states') {
      this.diversityStatusFlag = true;
      
    }
    else {
      
      this.diversityStatusFlag = false;
    }
  }

  changeSubCategory(count) {
    
    this.subCategories = [];
    for (var i = 0; i < count.length; i++) {
      for (var j = 0; j < this.businessList.length; j++) {
        if (count[i] == this.businessList[j].majorbusinesscategory) {
          this.subCategories.push(this.businessList[j].subbusinesscategory);
        }
      }
    }
  }

  typesOfSecurity: { name: string }[] = [
    { name: 'Primary- Full Edit' },
    { name: 'Cannot make changes' },
  ];


    typesOfDiversityStatus: { name: string, description: string }[] = [
    { name: 'MBE', description: 'Business at least 51% owned and actively managed by minority members (African-American, Hispanic, Asian, Native American)' },
    { name: 'WBE', description: 'Business at least 51% owned and actively managed by a woman or women' },
    { name: 'LGBT', description: ' Business at least 51% owned by lesbian, gay, bisexual or transgender individuals' },
    { name: 'WOSB as defined by the SBA', description: 'A small business that must be at least 51% owned and controlled by a woman or women' },
    { name: 'SDB as defined by SBA', description: 'A small business that must be at least 51% owned and controlled by a small and economically disadvantaged individual or individuals' },
    { name: 'VOSB as defined by the SBA', description: 'Business at least 51% owned by one or more veterans with management and daily business operation controlled by one or more veterans' },
    { name: 'SD-VOSB as defined by SBA', description: ' One or more veterans with service-connected disability own at least 51% and control management and daily business operations' },
    { name: 'HUB Zone as defined by SBA', description: 'A small business, its principal office must be located within a historically underutilized business zone and at least 35% of its employees must reside in the HUBZone' },
    { name: 'HUB/MI', description: ' Historically black college and universities or minority institutions as defined by the SBA' },
    { name: 'N/A', description: 'None of the above' }
  ]


  public myForm: FormGroup;
  public myReferenceForm: FormGroup;
  constructor(private _formBuilder: FormBuilder, private httpClient: HttpClient, private router: Router, private dropdownSvc: DropdownService, private onSubmitSvc: OnSubmitService) { }


  ngOnInit() {

    if (localStorage.getItem('loginEmailId') != null) {
      this.emailId = localStorage.getItem('loginEmailId')
      if ((this.emailId.indexOf('lilly') > 1) || (this.emailId.indexOf('elanco') > 1)) {
        this.emailId = '';
      }

    }

    if (localStorage.getItem('firstName') != null) {
      this.firstName = localStorage.getItem('firstName')
    }


    if (localStorage.getItem('country') != null) {
      this.country = localStorage.getItem('country')
      if (this.country.toLocaleLowerCase() == 'united states') {
        this.diversityStatusFlag = true;
      }
      this.disabledCountryFlag = false;
    }

    if (localStorage.getItem('lastName') != null) {
      this.LastName = localStorage.getItem('lastName')
    }


    if (localStorage.getItem('companyName') != null) {
      this.companyName = localStorage.getItem('companyName')
      this.disabledCompanyFlag = true;
    }

    this.dropdownSvc.getCountryList().subscribe((data: Array<object>) => {
      const countriesToFilter = ['Argentina', 'Bahrain', 'Chile', 'Cyprus', 'Dominican Republic', 'India', 'Indonesia', 'Russia', 'Saudi Arabia', 'South Africa', 'United Arab Emirates'];
      // Filter the countries from countriesToFilter array--> CHG2115645
      const countryData = data.filter((country: object) => {
        return !countriesToFilter.includes(country['countrycode']);
      });
      this.countryList = countryData; 
    });

    this.dropdownSvc.getCountryStateList().subscribe((data: Array<object>) => {
      this.countryStateList = data;
    });

    this.dropdownSvc.getMajorBusinessList().subscribe((data: Array<object>) => {
      this.majorBusinessCategoryList = data;
    });

    this.dropdownSvc.getBusinessList().subscribe((data: Array<object>) => {
      this.businessList = data;
    });

    this.dropdownSvc.getEmailList().subscribe((data: Array<object>) => {
      this.emailList = data;
    });

    this.dropdownSvc.getAllMasterData().subscribe((data:Array<any>)=>{
      
      var arr=[];
      var arr1=[];
      var arr2=[];
      var arr3=[];
      var arr4=[];
      var arr5=[];
      for(var i=0;i<data.length;i++){
        if(data[i].masterdataflag=='PYR'){
          arr.push(data[i].gimasterdata);
        }
        if(data[i].masterdataflag=='CYR'){
          arr1.push(data[i].gimasterdata);
        }
        if(data[i].masterdataflag=='TE'){
          arr2.push(data[i].gimasterdata);
        }
        if(data[i].masterdataflag=='SA'){
          arr3.push(data[i].gimasterdata);
        }
        if(data[i].masterdataflag=='OrgType'){
          arr4.push(data[i].gimasterdata);
        }
        if(data[i].masterdataflag=='BusType'){
          arr5.push(data[i].gimasterdata);
        }
      }
      this.PyrList=arr;
      this.CyrList=arr1;
      this.totalEmps=arr2;
      this.SalesAreaList=arr3;
      this.OrgType=arr4;
      this.BusType=arr5;
    })

    this.filteredOptions = this.myControl.valueChanges.pipe(
      startWith(''),
      map(value => this._filter(value))
    );

    this.firstFormGroup = this._formBuilder.group({
      company: new FormControl(this.companyName, [Validators.required]),
      corporateTelephoneNumber: new FormControl('', [Validators.required, phoneNumberValidator, Validators.maxLength(15)]),
      year: new FormControl('', [Validators.required, Validators.maxLength(4), NumberValidation]),
      currentYearRevenue: new FormControl('', Validators.required),
      priorYearOfRevenue: new FormControl('', Validators.required),
      totalEmployees: new FormControl('', Validators.required),
      url: new FormControl(''),
      orgType: new FormControl('', Validators.required),
      businessType: new FormControl('', Validators.required),
      subsidary: '',
      parentName: '',
      salesAreaCovered: '',
      country: new FormControl(this.country, Validators.required),
      diversityStatus: '',
      doingBusiness: ''
    });

    this.dropdownSvc.getCompanyList().subscribe((data: Array<object>) => {
      this.existingCompanyNameList = data;
      for (let i in this.existingCompanyNameList) {
        if (this.companyName.toLowerCase() == this.existingCompanyNameList[i].companyname.toLocaleLowerCase())
          this.firstFormGroup.get('company').setValue('');
      }
    });

    this.goodsAndServices = this._formBuilder.group({
      majorBusinessCategory: new FormControl('', Validators.required),
      subcategory: '',
      goodsAndServices: new FormControl('', [Validators.maxLength(3000), Validators.required]),
      valueProposition: new FormControl('', Validators.required),
    })

    this.myForm = this._formBuilder.group({
      supplierContact: this._formBuilder.array([
        this.initContact(),
      ])
    });



    this.myReferenceForm = this._formBuilder.group({
      supplierReferencesForm: this._formBuilder.array([
        this.initReferences(),
      ])
    });
    const control = <FormArray>this.myForm.controls['supplierContact'];
    control.controls[0].setValue({
      firstName: this.firstName,
      lastName: this.LastName,
      title: '',
      address: '',
      address1: '',
      address2: '',
      state: '',
      postalCode: '',
      faxNumber: '',
      emailId: this.emailId,
      country: '',
      city: '',
      phoneNumber: '',
      security: this.typesOfSecurity[0].name,
      jobTitle: '',
    })
  }

  initContact() {
    return this._formBuilder.group({
      firstName: new FormControl('', Validators.required),
      lastName: new FormControl('', Validators.required),
      title: '',
      address: '',
      address1: '',
      address2: '',
      state: '',
      postalCode: new FormControl('', Validators.required ),
      faxNumber: '',
      emailId: new FormControl('', [Validators.required, Validators.email]),
      country: new FormControl('', Validators.required),
      security: new FormControl('', Validators.required),
      city: '',
      phoneNumber: new FormControl('', [Validators.required, phoneNumberValidator, Validators.maxLength(15)]),
      jobTitle: ''
    })
  }
 
  initReferences() {
    return this._formBuilder.group({
      firstName: new FormControl('', Validators.required),
      lastName: new FormControl('', Validators.required),
      title: '',
      address: '',
      address1: '',
      address2: '',
      state: '',
      postalCode: new FormControl('', Validators.required),
      faxNumber: '',
      emailId: new FormControl('', Validators.required),
      country: '',
      city: '',
      companyName: new FormControl('', Validators.required),
      phoneNumber: new FormControl('', [Validators.required, phoneNumberValidator, Validators.maxLength(15)]),
    })
  }

  addSupplierContact() {
    const control = <FormArray>this.myForm.controls['supplierContact'];
    control.push(this.initContact());
  }

  removeSupplierContact(i: number) {
    const control = <FormArray>this.myForm.controls['supplierContact'];
    control.removeAt(i);
  }

  addSupplierRefereces() {
    const control = <FormArray>this.myReferenceForm.controls['supplierReferencesForm'];
    control.push(this.initReferences());
  }

  removeReferences(i: number) {
    const control = <FormArray>this.myReferenceForm.controls['supplierReferencesForm'];
    control.removeAt(i);
  }

  onSubmit() {
    let primaryFlag: boolean = false;
    let invalidflag: boolean = false;

    for (let i in this.myForm.value.supplierContact) {
      if (this.myForm.value.supplierContact[i].security.indexOf('Primary') > -1) {
        primaryFlag = true
      }
    }


    this.submitted = true;
    let firstGroupEmptyFields: Array<any> = [];
    let suppEmptyFields: Array<any> = [];
    let goodsEmptyFields: Array<any> = [];
    let suppRefEmptyFields: Array<any> = [];
    if (!this.selectedValue) {
      if (!(this.firstFormGroup.invalid)
        && !(this.goodsAndServices.invalid)
        && !(this.myForm.invalid)
        && primaryFlag) {
        swal({
          title: "Do you really want to submit this form?",
          text: "After submission the form will be moved to the next step for further actions!",
          icon: "warning",
          buttons: true,
          dangerMode: true,
        }).then((willDelete) => {
          if (willDelete) {
            var obj = {
              generalinformationdata: this.firstFormGroup.value,
              goodandservicesdata: this.goodsAndServices.value,
              contactsdata: this.myForm.value,
              referencesdata: this.myReferenceForm.value,
              //loggedInUser: localStorage.getItem('loginEmailId')
            }
            if (!this.selectedValue) {
              obj.referencesdata = [];
            }
            this.onSubmitSvc.postFormData(obj).subscribe((data) => {
              

              if (data.status === "success") {
                var obj1 = {
                  recipient: [this.emailId],
                  formdata: obj
                }

               // this.onSubmitSvc.mailSvc(obj.contactsdata).subscribe((data) => {
                this.onSubmitSvc.mailSvc(obj).subscribe((data) => {
                  
                });
                swal(
                  'Thank you for registering as a potential supplier with Eli Lilly and Company. An acknowledgement has been sent to the registered email'
                ).then((result) => {
                  localStorage.setItem('showcreate','true');
                  localStorage.setItem('afteraddme','true');
                  window.location.replace("/home");
                })
              }
              else {
                swal(
                  'Your form has not been submitted due to ' + data.status + '.Try again!'
                ).then((result) => {
                  
                })
              }
            });

          }
        })
      }
      else {
        
        for (let i in this.firstFormGroup.value) {
          if (this.firstFormGroup.get(i).value == "" && i.toLocaleLowerCase() == 'company') {
            firstGroupEmptyFields.push(" Please provide the Company Legal Name");
          }
          else if ((this.firstFormGroup.controls[i].status.toLocaleLowerCase() == 'invalid' && i.toLocaleLowerCase() == 'corporatetelephonenumber')) {
            firstGroupEmptyFields.push(" Please provide valid Telephone Number");
          }
          else if (this.firstFormGroup.get(i).value == "" && i.toLocaleLowerCase() == 'businesstype') {
            firstGroupEmptyFields.push(" Please provide the Business Type ");
          }
          else if (this.firstFormGroup.controls[i].status.toLocaleLowerCase() == 'invalid' && i.toLocaleLowerCase() == 'year') {
            firstGroupEmptyFields.push(" Please provide valid Year of Incorporation");
          }

          else if (this.firstFormGroup.get(i).value == "" && i.toLocaleLowerCase() == 'prioryearofrevenue') {
            firstGroupEmptyFields.push(" Please provide the Company Prior Year Revenue");
          }

          else if (this.firstFormGroup.get(i).value == "" && i.toLocaleLowerCase() == 'currentyearrevenue') {
            firstGroupEmptyFields.push(" Please provide the Company Current Year Revenue");
          }

          else if (this.firstFormGroup.get(i).value == "" && i.toLocaleLowerCase() == 'salesareacovered') {
            firstGroupEmptyFields.push(" Please provide the Sales Area Covered");
          }

          else if (this.firstFormGroup.get(i).value == "" && i.toLocaleLowerCase() == 'subsidary') {
            firstGroupEmptyFields.push(" Please provide the Subsidiary");
          }

          else if (this.firstFormGroup.get('subsidary').value.toLocaleLowerCase() == "yes" && (this.firstFormGroup.get(i).value == "" && i.toLocaleLowerCase() == 'parentname')) {
            firstGroupEmptyFields.push(" Please provide the Parent Legal Name");
          }

          else if (this.firstFormGroup.get(i).value == "" && i.toLocaleLowerCase() == 'country') {
            firstGroupEmptyFields.push(" Please provide the Country");
          }

          else if (this.firstFormGroup.get(i).value == "" && i.toLocaleLowerCase() == 'totalemployees') {
            firstGroupEmptyFields.push(" Please provide the Total Number of Employees");
          }

          else if (this.firstFormGroup.get(i).value == "" && i.toLocaleLowerCase() == 'orgtype') {
            firstGroupEmptyFields.push(" Please provide the Organization Type");
          }
          else if (this.firstFormGroup.get('country').value.toLocaleLowerCase() == "united states" && (this.firstFormGroup.get(i).value == "" && i.toLocaleLowerCase() == 'diversitystatus')) {
            firstGroupEmptyFields.push(" Please provide the Diversity Status(for United States)");
          }
        }
        if (firstGroupEmptyFields.length == 0) {
          firstGroupEmptyFields.push(" All mandatory fields are filled successfully");
        }
 
        for (let i in this.goodsAndServices.value) {
          if (this.goodsAndServices.get(i).value == "" && i.toLocaleLowerCase() == "majorbusinesscategory") {
            goodsEmptyFields.push(" Please provide the Major Business Category");
          }
          else if (this.goodsAndServices.get(i).value == "" && i.toLowerCase() == "goodsandservices") {
            goodsEmptyFields.push(" Please provide the Goods/Services Description");
          }
          else if (this.goodsAndServices.get(i).value == "" && i.toLocaleLowerCase() == "valueproposition") {
            goodsEmptyFields.push(" Please provide the Value Proposition");
          }
        }
        if (goodsEmptyFields.length == 0) {
          goodsEmptyFields.push(" All mandatory fields are filled successfully");
        }

        for (let i in this.myForm.value.supplierContact) {
          if (!primaryFlag) {
            suppEmptyFields.push(" Supplier Contacts must have atleast one primary contacts")
          }
          var temp = this.myForm.value.supplierContact[i];
          if (temp.firstName == "" || temp.lastName == "" || temp.address == "" || temp.country == "" || temp.state == "" || temp.city == "" || temp.phoneNumber == "" || temp.emailId == "" || temp.postalCode == "" || temp.security == "") {
            var id = Number(i) + 1;
            suppEmptyFields.push("\nContacts " + id + ":-");
          }
          if (this.myForm.value.supplierContact[i].firstName == null
            || this.myForm.value.supplierContact[i].firstName == "") {
            suppEmptyFields.push(" Please provide valid First Name");
          }
          if (this.myForm.value.supplierContact[i].lastName == ""
            || this.myForm.value.supplierContact[i].lastName == null) {
            suppEmptyFields.push(" Please provide valid Last Name");
          }
          if (this.myForm.value.supplierContact[i].emailId == ""
            || this.myForm.value.supplierContact[i].emailId == null
            || this.myForm.value.supplierContact[i].emailId.indexOf('lilly.com') > -1
            || this.myForm.value.supplierContact[i].emailId.indexOf('elanco.com') > -1) {
            suppEmptyFields.push(" Please provide valid Email Id");
          }
          if ((this.myForm.controls.supplierContact['controls'][i].controls.phoneNumber.status.toLocaleLowerCase() == 'invalid' && i.toLocaleLowerCase() == 'phoneNumber')) {
            suppEmptyFields.push(" Please provide valid Phone Number");
          }
          if (this.myForm.value.supplierContact[i].country == ""
            || this.myForm.value.supplierContact[i].country == null) {
            suppEmptyFields.push(" Please provide valid Country");
          }
          if (this.myForm.value.supplierContact[i].security == ""
            || this.myForm.value.supplierContact[i].security == null) {
            suppEmptyFields.push(" Please enter valid Security Level");
          }
          if (this.myForm.value.supplierContact[i].state == ""
            || this.myForm.value.supplierContact[i].state == null) {
            suppEmptyFields.push(" Please provide valid State");
          }
          if (this.myForm.value.supplierContact[i].city == ""
            || this.myForm.value.supplierContact[i].city == null) {
            suppEmptyFields.push(" Please provide valid City");
          }
          if (this.myForm.value.supplierContact[i].address == ""
            || this.myForm.value.supplierContact[i].address == null) {
            suppEmptyFields.push(" Please provide valid Address");
          }
          if (this.myForm.value.supplierContact[i].postalCode == ""
            || this.myForm.value.supplierContact[i].postalCode == null) {
            suppEmptyFields.push(" Please provide valid Postal Code");
          }

        }
        if (suppEmptyFields.length == 0) {
          if (this.myForm.invalid) {
            suppEmptyFields.push(" Entered Email Id is not valid")
          }
          else {
            suppEmptyFields.push(" All mandatory fields are filled successfully");
          }
        }

        if (this.selectedValue) {
          swal("Form Submission failed due to following errors :",
            "General Information:-\n" + "-----------------------------------------------------------\n" + firstGroupEmptyFields + "\n\n" +
            "Goods/Services:-\n" + "-----------------------------------------------------------\n" + goodsEmptyFields + "\n\n" +
            "Supplier Contacts:-\n" + "-----------------------------------------------------------\n" + suppEmptyFields + "\n\n" +
            "References:-\n" + "-----------------------------------------------------------\n" + suppRefEmptyFields);
        }
        else {
          swal("Form Submission failed due to following errors :",
            "General Information:-\n" + "-----------------------------------------------------------\n" + firstGroupEmptyFields + "\n\n" +
            "Goods/Services:-\n" + "-----------------------------------------------------------\n" + goodsEmptyFields + "\n\n" +
            "Supplier Contacts:-\n" + "-----------------------------------------------------------\n" + suppEmptyFields);
        }
      }
    }
    else {
      if (!(this.firstFormGroup.invalid)
        && !(this.goodsAndServices.invalid)
        && !(this.myForm.invalid)
        && primaryFlag
        && !(this.myReferenceForm.invalid)) {
        swal({
          title: "Do you really want to submit this form?",
          text: "After submission the form will be moved to the next step for further actions!",
          icon: "warning",
          buttons: true,
          dangerMode: true,
        }).then((willDelete) => {
          if (willDelete) {
            var obj = {
              generalinformationdata: this.firstFormGroup.value,
              goodandservicesdata: this.goodsAndServices.value,
              contactsdata: this.myForm.value,
              referencesdata: this.myReferenceForm.value,
              //loggedInUser: localStorage.getItem('loginEmailId')
            }
            if (!this.selectedValue) {
              obj.referencesdata = [];
            }
            this.onSubmitSvc.postFormData(obj).subscribe((data) => {
              

              if (data.status === "success") {
                var obj1 = {
                  recipient: [this.emailId],
                  formdata: obj
                }

               // this.onSubmitSvc.mailSvc(obj.contactsdata).subscribe((data) => {
                this.onSubmitSvc.mailSvc(obj).subscribe((data) => {
                });
                swal(
                  'Thank you for registering as a potential supplier with Eli Lilly and Company. An acknowledgement has been sent to the registered email.'
                ).then((result) => {
                  localStorage.setItem('showcreate','true');
                  localStorage.setItem('afteraddme','true');
                  window.location.replace("/home");
                })
              }
              else {
                swal(
                  'Your form has not been submitted due to ' + data.status + '.Try again!'
                ).then((result) => {
               
                })
              }
            });

          }
        })
      }
      else {
        
        for (let i in this.firstFormGroup.value) {
          if (this.firstFormGroup.get(i).value == "" && i.toLocaleLowerCase() == 'company') {
            firstGroupEmptyFields.push(" Please provide the Company Legal Name");
          }
          else if ((this.firstFormGroup.controls[i].status.toLocaleLowerCase() == 'invalid' && i.toLocaleLowerCase() == 'corporatetelephonenumber')) {
            firstGroupEmptyFields.push(" Please provide valid Telephone Number");
          }
          else if (this.firstFormGroup.get(i).value == "" && i.toLocaleLowerCase() == 'businesstype') {
            firstGroupEmptyFields.push(" Please provide the Business Type ");
          }
          else if (this.firstFormGroup.controls[i].status.toLocaleLowerCase() == 'invalid' && i.toLocaleLowerCase() == 'year') {
            firstGroupEmptyFields.push(" Please provide valid Year of Incorporation");
          }

          else if (this.firstFormGroup.get(i).value == "" && i.toLocaleLowerCase() == 'prioryearofrevenue') {
            firstGroupEmptyFields.push(" Please provide the Company Prior Year Revenue");
          }

          else if (this.firstFormGroup.get(i).value == "" && i.toLocaleLowerCase() == 'currentyearrevenue') {
            firstGroupEmptyFields.push(" Please provide the Company Current Year Revenue");
          }

          else if (this.firstFormGroup.get(i).value == "" && i.toLocaleLowerCase() == 'salesareacovered') {
            firstGroupEmptyFields.push(" Please provide the Sales Area Covered");
          }

          else if (this.firstFormGroup.get(i).value == "" && i.toLocaleLowerCase() == 'subsidary') {
            firstGroupEmptyFields.push(" Please provide the Subsidiary");
          }

          else if (this.firstFormGroup.get('subsidary').value.toLocaleLowerCase() == "yes" && (this.firstFormGroup.get(i).value == "" && i.toLocaleLowerCase() == 'parentname')) {
            firstGroupEmptyFields.push(" Please provide the Parent Legal Name");
          }

          else if (this.firstFormGroup.get(i).value == "" && i.toLocaleLowerCase() == 'country') {
            firstGroupEmptyFields.push(" Please provide the Country");
          }

          else if (this.firstFormGroup.get(i).value == "" && i.toLocaleLowerCase() == 'totalemployees') {
            firstGroupEmptyFields.push(" Please provide the Total Number of Employees");
          }

          else if (this.firstFormGroup.get(i).value == "" && i.toLocaleLowerCase() == 'orgtype') {
            firstGroupEmptyFields.push(" Please provide the Organization Type");
          }
          else if (this.firstFormGroup.get('country').value.toLocaleLowerCase() == "united states" && (this.firstFormGroup.get(i).value == "" && i.toLocaleLowerCase() == 'diversitystatus')) {
            firstGroupEmptyFields.push(" Please provide the Diversity Status(for United States)");
          }
        }
        if (firstGroupEmptyFields.length == 0) {
          firstGroupEmptyFields.push(" All mandatory fields are filled successfully");
        }

        
        for (let i in this.goodsAndServices.value) {
          if (this.goodsAndServices.get(i).value == "" && i.toLocaleLowerCase() == "majorbusinesscategory") {
            goodsEmptyFields.push(" Please provide the Major Business Category");
          }
          else if (this.goodsAndServices.get(i).value == "" && i.toLowerCase() == "goodsandservices") {
            goodsEmptyFields.push(" Please provide the Goods/Services Description");
          }
          else if (this.goodsAndServices.get(i).value == "" && i.toLocaleLowerCase() == "valueproposition") {
            goodsEmptyFields.push(" Please provide the Value Proposition");
          }
        }
        if (goodsEmptyFields.length == 0) {
          goodsEmptyFields.push(" All mandatory fields are filled successfully");
        }

        
        for (let i in this.myForm.value.supplierContact) {

          var temp = this.myForm.value.supplierContact[i];
          if (temp.firstName == "" || temp.lastName == "" || temp.address == "" || temp.country == "" || temp.state == "" || temp.city == "" || temp.phoneNumber == "" || temp.emailId == "" || temp.postalCode == "" || temp.security == "") {
            var id = Number(i) + 1;
            suppEmptyFields.push("\nContacts " + id + ":-");
          }
          if (this.myForm.value.supplierContact[i].firstName == null
            || this.myForm.value.supplierContact[i].firstName == "") {
            suppEmptyFields.push(" Please provide valid First Name");
          }
          if (this.myForm.value.supplierContact[i].lastName == ""
            || this.myForm.value.supplierContact[i].lastName == null) {
            suppEmptyFields.push(" Please provide valid Last Name");
          }
          if (this.myForm.value.supplierContact[i].emailId == ""
            || this.myForm.value.supplierContact[i].emailId == null
            || this.myForm.value.supplierContact[i].emailId.indexOf('lilly.com') > -1
            || this.myForm.value.supplierContact[i].emailId.indexOf('elanco.com') > -1) {
            suppEmptyFields.push(" Please provide valid Email Id");
          }
          if (this.myForm.controls.supplierContact['controls'][i].controls.phoneNumber.status.toLocaleLowerCase() == 'invalid') {
            suppEmptyFields.push(" Please provide valid Phone Number");
          }
          if (this.myForm.value.supplierContact[i].country == ""
            || this.myForm.value.supplierContact[i].country == null) {
            suppEmptyFields.push(" Please provide valid Country");
          }
          if (this.myForm.value.supplierContact[i].state == ""
            || this.myForm.value.supplierContact[i].state == null) {
            suppEmptyFields.push(" Please provide valid State");
          }
          if (this.myForm.value.supplierContact[i].city == ""
            || this.myForm.value.supplierContact[i].city == null) {
            suppEmptyFields.push(" Please provide valid City");
          }
          if (this.myForm.value.supplierContact[i].address == ""
            || this.myForm.value.supplierContact[i].address == null) {
            suppEmptyFields.push(" Please provide valid Address");
          }
          if (this.myForm.value.supplierContact[i].security == ""
            || this.myForm.value.supplierContact[i].security == null) {
            suppEmptyFields.push(" Please enter valid Security Level");
          }
          if (this.myForm.value.supplierContact[i].postalCode == ""
            || this.myForm.value.supplierContact[i].postalCode == null) {
            suppEmptyFields.push(" Please provide valid Postal Code");
          }

        }
        if (suppEmptyFields.length == 0) {
          if (this.myForm.invalid) {
            suppEmptyFields.push("Entered Email Id is not valid")
          }
          else {
            suppEmptyFields.push("All mandatory fields are filled successfully");
          }
        }



        for (let i in this.myReferenceForm.value.supplierReferencesForm) {
          var temp = this.myReferenceForm.value.supplierReferencesForm[i];

          if (temp.firstName == "" || temp.lastName == "" || temp.companyName == "" || temp.address == "" || temp.country == "" || temp.state == "" || temp.city == "" || temp.phoneNumber == "" || temp.emailId == "" || temp.postalCode == "") {
            var id = Number(i) + 1;
            suppRefEmptyFields.push("\nReferences " + id + ":-");
          }

          if (this.myReferenceForm.value.supplierReferencesForm[i].firstName == null
            || this.myReferenceForm.value.supplierReferencesForm[i].firstName == "") {
            suppRefEmptyFields.push(" Please provide valid First Name");
          }
          if (this.myReferenceForm.value.supplierReferencesForm[i].lastName == null
            || this.myReferenceForm.value.supplierReferencesForm[i].lastName == "") {
            suppRefEmptyFields.push(" Please provide valid Last Name");
          }
          if (this.myReferenceForm.value.supplierReferencesForm[i].emailId == null
            || this.myReferenceForm.value.supplierReferencesForm[i].emailId == ""
            || this.myReferenceForm.value.supplierReferencesForm[i].emailId.indexOf('lilly.com') > -1
            || this.myReferenceForm.value.supplierReferencesForm[i].emailId.indexOf('elanco.com') > -1) {
            suppRefEmptyFields.push(" Please provide valid Email Id");
          }
          if (this.myReferenceForm.value.supplierReferencesForm[i].country == null
            || this.myReferenceForm.value.supplierReferencesForm[i].country == "") {
            suppRefEmptyFields.push(" Please provide valid Country");
          }
          if (this.myReferenceForm.controls.supplierReferencesForm['controls'][i].controls.phoneNumber.status.toLocaleLowerCase() == 'invalid') {
            suppRefEmptyFields.push(" Please provide valid Phone Number");
          }
          if (this.myReferenceForm.value.supplierReferencesForm[i].companyName == null
            || this.myReferenceForm.value.supplierReferencesForm[i].companyName == "") {
            suppRefEmptyFields.push(" Please provide valid Company Name");
          }
          if (this.myReferenceForm.value.supplierReferencesForm[i].state == ""
            || this.myReferenceForm.value.supplierReferencesForm[i].state == null) {
            suppRefEmptyFields.push(" Please provide valid State");
          }
          if (this.myReferenceForm.value.supplierReferencesForm[i].city == ""
            || this.myReferenceForm.value.supplierReferencesForm[i].city == null) {
            suppRefEmptyFields.push(" Please provide valid City");
          }
          if (this.myReferenceForm.value.supplierReferencesForm[i].address == ""
            || this.myReferenceForm.value.supplierReferencesForm[i].address == null) {
            suppRefEmptyFields.push(" Please provide valid Address");
          }
          if (this.myReferenceForm.value.supplierReferencesForm[i].postalCode == ""
            || this.myReferenceForm.value.supplierReferencesForm[i].postalCode == null) {
            suppRefEmptyFields.push(" Please provide valid Postal Code");
          }
        }
        if (suppRefEmptyFields.length == 0) {
          if (this.myReferenceForm.invalid) {
            suppRefEmptyFields.push("Entered Email Id is not valid")
          }
          else {
            suppRefEmptyFields.push("All mandatory fields are filled successfully");
          }
        }



        if (this.selectedValue) {
          swal("Form Submission failed due to following errors :",
            "General Information:-\n" + "-----------------------------------------------------------\n" + firstGroupEmptyFields + "\n\n" +
            "Goods/Services:-\n" + "-----------------------------------------------------------\n" + goodsEmptyFields + "\n\n" +
            "Supplier Contacts:-\n" + "-----------------------------------------------------------\n" + suppEmptyFields + "\n\n" +
            "References:-\n" + "-----------------------------------------------------------\n" + suppRefEmptyFields);
        }
        else {
          swal("Form Submission failed due to following errors :",
            "General Information:-\n" + "-----------------------------------------------------------\n" + firstGroupEmptyFields + "\n\n" +
            "Goods/Services:-\n" + "-----------------------------------------------------------\n" + goodsEmptyFields + "\n\n" +
            "Supplier Contacts:-\n" + "-----------------------------------------------------------\n" + suppEmptyFields);
        }
      }
    }
  }

  
  selectOption(value) {
    if (value.checked === true) {
      this.selectedValue = true;
    } else {
      this.selectedValue = false;
    }
  }

  parentLegalName(value) {
    if (value === 'Yes') {
      this.parentLegalNameFlag = true;
      this.firstFormGroup.get('parentName').setValidators([Validators.required]);
      this.firstFormGroup.get('parentName').updateValueAndValidity();
    }
    else {
      this.parentLegalNameFlag = false;
      this.firstFormGroup.get('parentName').clearValidators();
      this.firstFormGroup.get('parentName').updateValueAndValidity();
    }
  }

  
  securityCheck(securityValue) {
    let securityArray: Array<any> = [];

    if (securityValue != null) {
      securityArray.push(securityValue);
      for (let i in securityValue) {
        if (securityValue[i].toLowerCase() == "cannot make changes") {
          for (let i in securityValue) {
            securityValue.splice(i, 1)
          }
        }
        else {
          securityValue.push(securityArray)
        }
      }

    }
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.options.filter(option => option.toLowerCase().indexOf(filterValue) === 0);
  }

  existingCompanyNameList: Array<any>;

  public onBlur(formControlName: string) {
    let enteredValue = this.firstFormGroup.controls[formControlName].value;
    for (let i in this.existingCompanyNameList) {
      if (enteredValue.toLowerCase() == this.existingCompanyNameList[i].companyname.toLocaleLowerCase()) {
        swal(
          'Supplier with the same name already exists. Please provide another Company Legal Name',
        ).then((result) => {
          this.firstFormGroup.get(formControlName).setValue('');
        })

      }
    }
  }


  public onBlurContactEmailCheck(formControlName: string, ind: number) {

    if (this.myForm.value.supplierContact[ind].emailId.indexOf('lilly.com') > -1
      || this.myForm.value.supplierContact[ind].emailId.indexOf('elanco.com') > -1
    ) {
      swal(
        'User with Lilly/Elanco email address are not allowed to be part of contacts.',
      )

      var control = <FormArray>this.myForm.controls['supplierContact'];
      control.controls[ind].setValue({
        contactId: this.myForm.value.supplierContact[ind].contactId,
        firstName: this.myForm.value.supplierContact[ind].firstName,
        lastName: this.myForm.value.supplierContact[ind].lastName,
        title: this.myForm.value.supplierContact[ind].title,
        address: this.myForm.value.supplierContact[ind].address,
        address1: this.myForm.value.supplierContact[ind].address1,
        address2: this.myForm.value.supplierContact[ind].address2,
        state: this.myForm.value.supplierContact[ind].state,
        postelcode: this.myForm.value.supplierContact[ind].postelcode,
        faxnumber: this.myForm.value.supplierContact[ind].faxnumber,
        emailId: '',
        country: this.myForm.value.supplierContact[ind].country,
        security: this.myForm.value.supplierContact[ind].security,
        city: this.myForm.value.supplierContact[ind].city,
        phoneNumber: this.myForm.value.supplierContact[ind].phoneNumber,
        jobTitle: this.myForm.value.supplierContact[ind].jobTitle
      });
    }
    else {
      for (let i in this.emailList) {
        if (this.emailList[i].email == this.myForm.controls.supplierContact.value[ind].emailId) {
          swal('User Already registered!');
          break;
        }
      }
      for (let i in this.myForm.value.supplierContact) {
        var i1 = Number(i);
        if (i1 == ind) {
          continue;
        }
        else {
          if (this.myForm.controls.supplierContact.value[ind].emailId == this.myForm.controls.supplierContact.value[i].emailId) {
            swal('You cannot use the same email Id for two contacts at the same time!');
          }
        }
      }
    }
  }

  public onBlurRefEmailCheck(formControlName: string, ind: number) {
    if (this.myReferenceForm.value.supplierReferencesForm[ind].emailId.indexOf('lilly.com') > -1
      || this.myReferenceForm.value.supplierReferencesForm[ind].emailId.indexOf('elanco.com') > -1
    ) {
      swal(
        'User with Lilly/Elanco email address are not allowed to be part of contacts.',
      )
      var control1 = <FormArray>this.myReferenceForm.controls['supplierReferencesForm'];
      control1.controls[ind].setValue({
        referenceId: this.myReferenceForm.value.supplierReferencesForm[ind].referenceId,
        firstName: this.myReferenceForm.value.supplierReferencesForm[ind].firstName,
        lastName: this.myReferenceForm.value.supplierReferencesForm[ind].lastName,
        title: this.myReferenceForm.value.supplierReferencesForm[ind].title,
        address: this.myReferenceForm.value.supplierReferencesForm[ind].address,
        address1: this.myReferenceForm.value.supplierReferencesForm[ind].address1,
        address2: this.myReferenceForm.value.supplierReferencesForm[ind].address2,
        state: this.myReferenceForm.value.supplierReferencesForm[ind].state,
        postalCode: this.myReferenceForm.value.supplierReferencesForm[ind].postalCode,
        faxnumber: this.myReferenceForm.value.supplierReferencesForm[ind].faxnumber,
        emailId: '',
        country: this.myReferenceForm.value.supplierReferencesForm[ind].country,
        company: this.myReferenceForm.value.supplierReferencesForm[ind].company,
        city: this.myReferenceForm.value.supplierReferencesForm[ind].city,
        phoneNumber: this.myReferenceForm.value.supplierReferencesForm[ind].phoneNumber,
      })
    }
    else {
      for (let i in this.myReferenceForm.value.supplierReferencesForm) {
        var i1 = Number(i);
        if (i1 == ind) {
          continue;
        }
        else {
          if (this.myReferenceForm.value.supplierReferencesForm[ind].emailId == this.myReferenceForm.value.supplierReferencesForm[i].emailId) {
            swal('You cannot use the same email Id for two references at the same time!');
          }
        }
      }
    }
  }


  onCancel() {
    swal({
      title: "Do you really want to cancel this Supplier Form?",
      text: "Once you press 'OK' this form will redirect you to the Home Page!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        this.router.navigate(['/home']);
      }
    });
  }

  selectedOptions: Array<any>;
  onDiversityListControlChanged(list) {

    this.selectedOptions = list.selectedOptions.selected.map(item => item.value);
    if (this.selectedOptions.length == 0) {
    this.firstFormGroup.get('diversityStatus').setValidators([Validators.required]);
    this.firstFormGroup.get('diversityStatus').updateValueAndValidity();
    }
    else {
    this.firstFormGroup.get('diversityStatus').clearValidators();
    this.firstFormGroup.get('diversityStatus').updateValueAndValidity();
    }
    for (let i in this.selectedOptions) {
    if ((this.selectedOptions.indexOf('N/A') > -1)) {
    this.firstFormGroup.get('diversityStatus').setValue(['N/A']);
    
    }
    }
    }

}
