/*********************************************************************************
Class Name      : StateFilter
Description     : This class is used to populate state dropdown based on selected country
Created By      : TCS               
Created Date    : 28-Jan-2019  
---------------------------------------------------------------------------------- 
Developer:OSF_Dev_Team                   Date : 28-Jan-2019                  
-----------------------------------------------------------------------------------           
*********************************************************************************/  

import { PipeTransform, Pipe } from '@angular/core';


@Pipe({
    name: 'statepipefilter'
})

export class StateFilter implements PipeTransform {

    transform(countryStateList: Array<any>, countrySelected: string) {
       return countryStateList.filter(c=>c.countrycode.includes(countrySelected));
    }
}
