/*********************************************************************************
Class Name      : DropdownService
Description     : This class is used to fetch data from backend service
Created By      : TCS               
Created Date    : 28-Jan-2019  
---------------------------------------------------------------------------------- 
Developer:OSF_Dev_Team                   Date : 28-Jan-2019                  
-----------------------------------------------------------------------------------           
*********************************************************************************/ 

import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { response } from './assets/Response';
import { Observable } from 'rxjs';
import { duplicateData } from './assets/duplicateData';
import { addMeEmailData } from './assets/addMeEmailData';
import { masterData } from './assets/masterData';
import { Env } from 'src/env-variables/env';

@Injectable({
  providedIn: 'root'
})
export class DropdownService {
  URL:string;

  constructor(private  httpClient:  HttpClient,private env:Env) {
    this.URL=env.OSF_BACKEND_URL;
    this.frontendUrl=env.Prospective_URL;
  }  
  getReferencesList(){
      return this.httpClient.get(this.URL+'referenceslist');
  }

  getEmailList() {
      return this.httpClient.get(this.URL + 'getemails');
  }

  getCountryList() {
      return this.httpClient.get(this.URL + 'countrylist');
  }

  getMajorBusinessList() {
      return this.httpClient.get(this.URL + 'majorbusinesslist');
  }

  getBusinessList() {
      return this.httpClient.get(this.URL + 'businesslist');
  }

  getCountryStateList() {
      return this.httpClient.get(this.URL + 'countrystatelist');
  }

  getCompanyList() {
      return  this.httpClient.get(this.URL + 'companylist');
  }

  getAllMasterData(){
    return this.httpClient.get(this.URL+'allmasterdata');
  }

  getTotalEmps() {
      return this.httpClient.get<response>(this.URL + 'masterdata', { headers: new HttpHeaders({ 'flag': 'TE' }) });
  }

  getPyrList() {
      return this.httpClient.get<response>(this.URL + 'masterdata', { headers: new HttpHeaders({ 'flag': 'PYR' }) });
  }

  getCyrList() {
      return this.httpClient.get<response>(this.URL + 'masterdata', { headers: new HttpHeaders({ 'flag': 'CYR' }) });
  }

  getSalesAreaList() {
      return this.httpClient.get<response>(this.URL + 'masterdata', { headers: new HttpHeaders({ 'flag': 'SA' }) });
  }

  getOrgType() {
      return this.httpClient.get<response>(this.URL + 'masterdata', { headers: new HttpHeaders({ 'flag': 'OrgType' }) });
  }

  getBusType() {
    return this.httpClient.get<response>(this.URL + 'masterdata', { headers: new HttpHeaders({ 'flag': 'BusType' }) });
  }

  getDuplicateCompanyList(obj: duplicateData): Observable<response> {
    return this.httpClient.post<response>(this.URL + 'DuplicateComp', obj);
  }

  postAddMeEmail(mailObj: addMeEmailData): Observable<response> {
    
    return this.httpClient.post<response>(this.URL + 'AddmeMail', mailObj);
  }

  getUserRole(mailObj: duplicateData): Observable<response> {
    return this.httpClient.get<response>(this.URL + "UserCheck/" + mailObj);
  }

  //General Info Master Data
  getGeneralInfoMasterDataList() {
    return this.httpClient.get<response>(this.URL + 'GenDataForAdmin');
  }

  //Goods and Service Master Data
  getGoodsAndServiceMasterDataList() {
    return this.httpClient.get<response>(this.URL + 'GoodsDataForAdmin');
  }

  deleteGenerInfoData(obj: masterData): Observable<response> {

    return this.httpClient.post<response>(this.URL + 'UpdateGenInfoMaster', obj);
  }

  addGenerInfoData(obj: masterData): Observable<response> {
    return this.httpClient.post<response>(this.URL + 'UpdateGenInfoMaster', obj);
  }

  updateGenerInfoData(obj: masterData): Observable<response> {
    return this.httpClient.post<response>(this.URL + 'UpdateGenInfoMaster', obj);
  }

  deleteGoodsAndServicesInfoData(obj: masterData): Observable<response> {
    
    return this.httpClient.post<response>(this.URL + 'updategoodmasterdata', obj);
  }

  updateGoodsAndServicesInfoData(obj: masterData): Observable<response> {
    return this.httpClient.post<response>(this.URL + 'updategoodmasterdata', obj);
  }

  addGoodsAndServicesInfoData(obj: masterData): Observable<response> {
    return this.httpClient.post<response>(this.URL + 'updategoodmasterdata', obj);
  }
  
  frontendUrl:string;
  getSSOResponse(): Observable<response>{    
    return this.httpClient.post<response>(this.frontendUrl + 'home', '');
  }
  
  getJWTApi(obj){
   return this.httpClient.post<response>(this.URL + 'test',obj);
  }

  setSessionKill(){
    return this.httpClient.get<response>(this.URL + 'testend');
  }

  closeApi(){
    return this.httpClient.get<response>(this.URL + 'close');
  }
}
