/*********************************************************************************
Class Name      : AppComponent
Description     : This class is used distinguish supplier's role
Created By      : TCS               
Created Date    : 28-Jan-2019  
---------------------------------------------------------------------------------- 
Developer:OSF_Dev_Team                   Date : 28-Jan-2019                  
-----------------------------------------------------------------------------------           
*********************************************************************************/ 

import { Component, OnInit, ViewChild, OnDestroy, } from '@angular/core';
import { Router } from '@angular/router';
import { DropdownService } from './dropdown.service';
import { UserIdleService } from 'angular-user-idle';
import { DomSanitizer } from '@angular/platform-browser';
import { MatIconRegistry } from '@angular/material';
import { OktaAuthService } from '@okta/okta-angular'




@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit{
  title = 'frontend';
  selectedValue: boolean = false;
  username: string;
  password: string;

  
  
  @ViewChild('sidenav') sidenav: MatIconRegistry;
  isExpanded = true;
  showSubmenu: boolean = false;
  isShowing = false;
  showSubSubMenu: boolean = false;
  emailId: string = '';
  firstName: string = '';
  lastName: string = '';
  country: string = '';
  companyName: string = '';
  message: string = '';
  primaryFlag: boolean = false;
  showCreateFlag: boolean = false;
  showAddMeFlag:boolean=false;
  userDataList: Array<any>;
  readOnlyFlag: boolean = false;
  headerFlag:boolean=false;
  isAuthenticated: boolean
  mouseenter() {
    if (!this.isExpanded) {
      this.isShowing = true;
    }
  }

  mouseleave() {
    if (!this.isExpanded) {
      this.isShowing = false;
    }
  }
  constructor(iconRegistry: MatIconRegistry, sanitizer: DomSanitizer, private router: Router, private dropdownSvc: DropdownService,private userIdle: UserIdleService,public oktaAuth: OktaAuthService) {
    iconRegistry.addSvgIcon(
      'thumbs-up',
      sanitizer.bypassSecurityTrustResourceUrl('assets/img/examples/thumbup-icon.svg'));
    this.oktaAuth.$authenticationState.subscribe(isAuthenticated => this.isAuthenticated = isAuthenticated);  
  }

  viewRoute() {
    
    this.router.navigate(['/general-info/' + this.emailId.toLowerCase()], { skipLocationChange: true });
  }

  updateRoute() {
    this.router.navigate(['/updateSupplierForm/' + this.emailId], { skipLocationChange: true });
  }

  
 async ngOnInit() {

  
    //check if user is authenticated
     this.isAuthenticated = await this.oktaAuth.isAuthenticated();

     

     this.userIdle.startWatching();
    
     //Start watching when user idle is starting.
     this.userIdle.onTimerStart().subscribe(count => console.log(count));
     
     //Start watch when time is up.
     this.userIdle.onTimeout().subscribe(() => {console.log('Timeout!');
     sessionStorage.clear();
     localStorage.clear();
     window.location.replace('/login');
    });
    
    if(localStorage.getItem('userToken')=='true'){
      this.headerFlag=true;
    }

    if (localStorage.getItem('loginEmailId') != null) {
      this.emailId = localStorage.getItem('loginEmailId').toLowerCase();
    }

    //First name
    if (localStorage.getItem('firstName') != null) {
      this.firstName = localStorage.getItem('firstName')
    }

    //Country
    if (localStorage.getItem('country') != null) {
      this.country = localStorage.getItem('country')
    }

    //Last Name
    if (localStorage.getItem('lastName') != null) {
      this.lastName = localStorage.getItem('lastName')
    }

    // Compnay Name
    if (localStorage.getItem('companyName') != null) {
      this.companyName = localStorage.getItem('companyName')
    }
    
    var obj = {
      checkCompany: this.companyName,
      checkEmailId: this.emailId
    }

    if(this.emailId!=""){
    this.dropdownSvc.getDuplicateCompanyList(obj).subscribe((data) => {
      this.userDataList = data.data;
      this.message = data.message;
        if (this.message.indexOf('Primary') > -1) {
          this.primaryFlag = true;
          localStorage.setItem('primaryFlag', 'true');
        }
        else if (this.message.indexOf('Read') > -1) {
           this.readOnlyFlag = true;
           localStorage.setItem('readOnlyFlag', 'true');
        }
       //this.userDataList.length making false to display the create page at first login
       else if (this.userDataList.length > 0) 
      {
        if(!(localStorage.getItem('showAddMeFlag')=='true'))
        {
        localStorage.setItem('showCreateFlag', 'true');
        this.router.navigate(['/create']); 
        }
       else
          {
            this.showCreateFlag = true;  
          }
      }
      else 
      {
        this.showCreateFlag = true;
        localStorage.setItem('showCreateFlag', 'true');
      }
      /*
        else if (this.userDataList.length > 0) {
          if(!(localStorage.getItem('showAddMeFlag')=='true')){
            
            localStorage.setItem('showcreate','false');
            
            this.router.navigate(['/addme']);
          }
          else{
            this.showCreateFlag = true;  
          }
        }
        else {
          this.showCreateFlag = true;
          localStorage.setItem('showCreateFlag', 'true');
        }*/

    });
    }

  
  }
  stop() {
    this.userIdle.stopTimer();
  }
 
  stopWatching() {
    this.userIdle.stopWatching();
  }
 
  startWatching() {
    this.userIdle.startWatching();
  }
 
  restart() {
    this.userIdle.resetTimer();
  }
  async logout() {
    console.log("line 201 logout")
    await this.oktaAuth.signOut();
    sessionStorage.clear();
    localStorage.clear();
  /*  localStorage.removeItem('userToken');
    localStorage.removeItem('loginEmailId');
    localStorage.removeItem('firstName');
    localStorage.removeItem('lastName');*/
  //localStorage.removeItem('country');// to be added in okta
  //localStorage.removeItem('companyName');// to be added in okta
  // localStorage.removeItem('accesstoken')// to be added in okta
  }
  
}



