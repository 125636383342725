/*********************************************************************************
Class Name      : HomeComponent
Description     : This class is used to display home page
Created By      : TCS               
Created Date    : 28-Jan-2019  
---------------------------------------------------------------------------------- 
Developer:OSF_Dev_Team                   Date : 28-Jan-2019                  
-----------------------------------------------------------------------------------           
*********************************************************************************/  

import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { DropdownService } from '../dropdown.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

    constructor(private router: Router, private dropdownSvc: DropdownService) { }


  ngOnInit() {
    //console.log("test session");
    //console.log(sessionStorage.length);
    if(localStorage.getItem('userToken')!='true'){
     // console.log("inside if");
      this.router.navigate(['/login']);
    }
  }
}
