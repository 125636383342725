/*********************************************************************************
Class Name      : LoginComponent
Description     : This class is used to display login page
Created By      : TCS               
Created Date    : 28-Jan-2019  
---------------------------------------------------------------------------------- 
Developer:OSF_Dev_Team                   Date : 28-Jan-2019                  
-----------------------------------------------------------------------------------           
*********************************************************************************/ 
/*
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { DropdownService } from '../dropdown.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  constructor(private route: ActivatedRoute,
    private router: Router, private dropdownSvc: DropdownService) { }

  ngOnInit() {
    if(localStorage.getItem('userToken')=='true'){
      this.router.navigate(['/home']);
    }
  }

}*/



import { Component, OnInit }from '@angular/core';
import { OktaAuthService } from '@okta/okta-angular';

import * as OktaSignIn from '@okta/okta-signin-widget';
import {MatDialog,MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { environmentOkta } from 'src/env-variables/environmentOkta';


const DEFAULT_ORIGINAL_URI = window.location.origin;

let USE_INTERACTION_CODE = false;


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  signIn: any;
  dialogref:any;
  authsuccess:boolean;
  constructor(public oktaAuth: OktaAuthService,public dialog: MatDialog) {
    this.signIn = new OktaSignIn({
      /**
       * Note: when using the Sign-In Widget for an OIDC flow, it still
       * needs to be configured with the base URL for your Okta Org. Here
       * we derive it from the given issuer for convenience.
       */
      baseUrl: environmentOkta.baseUrl.split('/oauth2')[0],
      clientId: environmentOkta.clientId,
      redirectUri: environmentOkta.redirectUri,
     // prompt:"login",
      authParams: {
        issuer: environmentOkta.issuer,
        scopes: ['openid', 'profile', 'email'],
      },
      registration:{
        preSubmit: function (postData, onSuccess, onFailure) {
          // handle preSubmit callback
          console.log("line 69",postData)
          let email=postData.email.toLocaleLowerCase()
          if((email.indexOf('lilly.com') > -1) || (email.indexOf('elanco.com') > -1)){
          var error = {
            "errorSummary": "Lilly/Elanco Email Id is not allowed"
          };
          onFailure(error);
          }
          else{
            onSuccess(postData)
          }

       }
      },
      features: {
        registration: true,                 // Enable self-service registration flow
        rememberMe: true,                   // Setting to false will remove the checkbox to save username
        //multiOptionalFactorEnroll: true,  // Allow users to enroll in multiple optional factors before finishing the authentication flow.
        //selfServiceUnlock: true,          // Will enable unlock in addition to forgotten password
        //smsRecovery: true,                // Enable SMS-based account recovery
        //callRecovery: true,               // Enable voice call-based account recovery
        router: true,                       // Leave this set to true for the API demo
      },
      useInteractionCodeFlow: USE_INTERACTION_CODE,
    });
  }

  async ngOnInit() {
    // When navigating to a protected route, the route path will be saved as the `originalUri`
    // If no `originalUri` has been saved, then redirect back to the app root
    this.authsuccess = await this.oktaAuth.isAuthenticated();
    console.log("check auth",this.authsuccess)
    if(this.authsuccess)
    {
      const userClaims = await this.oktaAuth.getUser();
        console.log(userClaims)
        localStorage.setItem('userToken','true');
        localStorage.setItem('loginEmailId', userClaims.email);
        localStorage.setItem('firstName', userClaims.given_name);
        localStorage.setItem('lastName', userClaims.family_name);
        console.log(userClaims)
       //localStorage.setItem('country', result.userData.supplierCountry);// to be added in okta
        localStorage.setItem('companyName', userClaims.given_name);// to be added in okta
       // localStorage.setItem('accesstoken', result.accessToken)// to be added in okta

        window.location.replace('/home');
    }
    const originalUri = this.oktaAuth.getOriginalUri();
    if (!originalUri || originalUri === DEFAULT_ORIGINAL_URI) {
      this.oktaAuth.setOriginalUri('/');
    }
    if(localStorage.getItem('userToken')=='true')
    {
      window.location.replace('/home');
    }
    await this.signIn.showSignInAndRedirect({
      el: '#sign-in-widget',
    })
    console.log("MFA")
   
    
    /*this.signIn.showSignInAndRedirect({
      el: '#sign-in-widget',
    }).then(tokens => {
      console.log("MFA response",tokens)
      // Remove the widget
      this.signIn.remove();
      // In this flow the redirect to Okta occurs in a hidden iframe
      this.oktaAuth.handleLoginRedirect(tokens).then(async res=>{
  
        const userClaims = await this.oktaAuth.getUser();
        console.log(userClaims)
        localStorage.setItem('userToken','true');
        localStorage.setItem('loginEmailId', userClaims.email);
        localStorage.setItem('firstName', userClaims.given_name);
        localStorage.setItem('lastName', userClaims.family_name);
        console.log(userClaims)
       //localStorage.setItem('country', result.userData.supplierCountry);// to be added in okta
        localStorage.setItem('companyName', userClaims.given_name);// to be added in okta
       // localStorage.setItem('accesstoken', result.accessToken)// to be added in okta

        window.location.replace('/home');
      
      });
    }).catch(err => {
      // Typically due to misconfiguration
      throw err;
    });*/
  }

  ngOnDestroy() {
    this.signIn.remove();
  }
  openDialog() {
    this.dialogref=this.dialog.open(DialogElementsExampleDialog,{width: '250px',height:'250px'});
  }
  closeDialog() {
    this.dialogref.close()
  }
  

}
@Component({
  selector: 'dialog-elements-example-dialog',
  template: `<h1 mat-dialog-title>Attention</h1>
  <div mat-dialog-content >
  <p>changes in authentication</p>
  </div>
  <div mat-dialog-actions>
  <button mat-button (click)="closeDialog()">Close</button>
  </div>`,
})
export class DialogElementsExampleDialog {
  constructor(
    public dialogRef: MatDialogRef<DialogElementsExampleDialog>,
  ) {}

  closeDialog(): void {
    this.dialogRef.close();
  }
}

