/*********************************************************************************
Component Name      : CreateComponent
Description     : This component is used to configure custom carousel
Created By      : TCS               
Created Date    : 28-Jan-2019  
---------------------------------------------------------------------------------- 
Developer:OSF_Dev_Team                   Date : 28-Jan-2019                  
-----------------------------------------------------------------------------------           
*********************************************************************************/  

import { Component, OnInit } from '@angular/core';
import { NgbCarouselConfig } from '@ng-bootstrap/ng-bootstrap';

@Component({
  styleUrls: ['./edit.component.css'],
  templateUrl: './edit.component.html',
  providers: [NgbCarouselConfig]  // add NgbCarouselConfig to the component providers
})
export class NgbdCarouselConfig implements OnInit{
  public carouse; 
  constructor() {
  }

  ngOnInit(){

   // this.carouse = document.getElementById(".carousel");
  }
}
