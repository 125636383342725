/*********************************************************************************
Class Name      : OnSubmitService
Description     : This class is used to fetch data from backend service
Created By      : TCS               
Created Date    : 28-Jan-2019  
---------------------------------------------------------------------------------- 
Developer:OSF_Dev_Team                   Date : 28-Jan-2019                  
-----------------------------------------------------------------------------------           
*********************************************************************************/ 

import { Injectable } from '@angular/core';
import { HttpClient } from  '@angular/common/http';
import { postdata } from 'src/app/assets/PostData';
import { maildata } from 'src/app/assets/maildata';
import { Observable } from 'rxjs';
import { response } from './assets/Response';
import { Env } from './../env-variables/env'

@Injectable({
  providedIn: 'root'
})
export class OnSubmitService {
  URL:string;

  constructor(private  httpClient:  HttpClient,private env:Env) {
    this.URL=env.OSF_BACKEND_URL;
  }

  postFormData(obj:postdata):Observable<response>{
    return this.httpClient.post<response>(this.URL+'formpost',obj);
  }

  updateFormData(obj:postdata):Observable<response>{
    return this.httpClient.post<response>(this.URL+'updateform',obj);
  }

  mailSvc(obj:maildata):Observable<response>{
    return this.httpClient.post<response>(this.URL+"mail",obj);
  }
  mailSvcUpdate(obj:maildata):Observable<response>{
    return this.httpClient.post<response>(this.URL+"UpdateMail",obj);
  }
  getForm(email:string){
    return this.httpClient.get(this.URL+"formview/"+email);
  }

  getGnS(email:string){
    return this.httpClient.get(this.URL+"getgns/"+email);
  }

  getCons(email:string){
    return this.httpClient.get(this.URL+"getcons/"+email);
  }

  getRefs(email:string){
    return this.httpClient.get(this.URL+"getrefs/"+email);
  }

  deleteSupp(obj:any){
    return this.httpClient.post<response>(this.URL+"deletesupp",obj);
  }

  deleteSuppView(obj:any){
    return this.httpClient.post<response>(this.URL+"deletesuppview",obj);
  }

  getView(email:string){
    return this.httpClient.get(this.URL+"getview/"+email);
  }
}
